import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Table from '../components/Table'
import List from '../components/List'
import { Link } from 'gatsby'

export default function UsabilityEngineeringTemplate({ data, pageContext }) {
  const { frontmatter } = data.markdownRemark
  const { filePath } = pageContext
  const filePathParts = filePath.split('/')
  const productName = filePathParts[0]
  const documentName = filePathParts[2]
  return (
    <Layout title={documentName} filePath={filePath} tableOfContents="true">
      <section className="section sectionbody">
        <div className="block">
          <h1 className="title is-1">1 Executive Summary</h1>
          <p>
            The {productName} has been found to be safe and effective for the
            intended users, uses, and use environments.
          </p>
        </div>
        <div className="block">
          <h3 className="title is-3">
            1.1 Evaluation Process and Results Summary
          </h3>
          <p>
            Throughout the design process, a number of formative evaluations
            were conducted in order to identify any unknown potential use
            errors. The results of these evaluations contributed to relevant
            design modifications that could be implemented into the final design
            of the product (see Section 6) prior to design release such as:
            <List list={frontmatter.relevantDesignModificationsList} />
          </p>
          <p>
            Once the product was verified to comply with all the design
            specifications outlined in the Design Inputs, and the latest
            preliminary usability testing results yielded minimal use errors,
            testing was expanded to incorporate representative users and use
            environments in the Human Factors Evaluation (see Section 8). This
            evaluation showed that the {productName} could be safely and
            effectively handled by the intended users in the proper use
            scenarios such as:
            <List list={frontmatter.properUseScenariosList} />
          </p>
        </div>
        <div className="block">
          <h3 className="title is-3">1.2 Justification of Residual Risk</h3>
          <p>
            Even with risk analysis, formative evaluations, and human factors
            testing, no product can be 100% risk free. The risks associated with
            the {productName} , however, have been mitigated as much as
            possible. The residual use-related risks that remain are:
            <List list={frontmatter.residualRisksList} />
          </p>

          <p>
            Attempts to reduce these risks further are not possible or
            practicable because {frontmatter.riskJustificationReason}. The
            benefits of using the {productName} far outweigh the residual risks
            at this time.
          </p>
          <p>Link to Risk Management Plan</p>
        </div>
      </section>
      <section className="section sectionbody">
        <div className="block">
          <h1 className="title is-1">2 Use Descriptions</h1>
        </div>
        <div className="block">
          <p>{frontmatter.intendedUseDescription}</p>
        </div>

        <div className="block">
          <h4 className="title is-4">2.1 Intended Use Summary</h4>

          <p>
            The {productName} is intended to {frontmatter.intendedUseSummary}.
          </p>
        </div>
        <div className="block">
          <h3 className="title is-3">2.2 Users</h3>
          <p>
            The intended user groups and their anticipated training requirements
            are:
          </p>
        </div>
        <div className="block">
          <Table table={frontmatter.usersTable} />
          <p>
            The differences in these user’s capabilities could affect their
            interactions with the {productName} in the following ways:
          </p>
          <List list={frontmatter.impactsofDifferencesList} />
        </div>

        <div className="block">
          <h3 className="title is-3">2.3 Use Environment</h3>
          <p>The expected use environments are:</p>
          <Table table={frontmatter.expectedUseEnvironmentsTable} />
        </div>
        <div className="block">
          <h3 className="title is-3">2.4 Operational Context</h3>
          <p>
            Successful operation of the {productName} by the intended users is
            conditional on the following requirements:
          </p>
          <List list={frontmatter.requirementsList} />
        </div>
        <div className="block">
          <h3 className="title is-3">
            2.5 Training Materials Intended for Users Prior to Use
          </h3>
          <List list={frontmatter.materialsList} />
          <p>See Appendix A for sample materials</p>
        </div>
      </section>
      <section className="section sectionbody">
        <h1 className="title is-1">3 Device User Interface</h1>
        {frontmatter.hasUserInterface === 'yes' ? (
          <div>
            <div className="block">
              <h4 className="title is-4">3.1 Description</h4>
              <p>
                Users are intended to interface with the {productName} via{' '}
                {frontmatter.userInterface}.
              </p>
            </div>
            <div className="block">
              <h4 className="title is-4">3.2 User Interfaces</h4>
              <p>The following components are the main user interfaces:</p>
              <Table table={frontmatter.userInterfacesTable} />
            </div>
            <div className="block">
              <h4 className="title is-4">Order of Operations</h4>
              <p>The intended sequence of user actions of the device is:</p>
              <Table table={frontmatter.orderOfOperationsTable} />
            </div>
          </div>
        ) : (
          <h4>Not Required</h4>
        )}
        <div className="block">
          <h3 className="title is-3">Accompanying Materials</h3>
          <p>The following materials will be included with the device:</p>
          <List list={frontmatter.accompanyingMaterialsList} />
          <p>See Appendix B for sample materials.</p>
        </div>
      </section>
      <section className="section sectionbody">
        <h1 className="title is-1">4 Known Use Problems</h1>
        <div className="block">
          <h3 className="title is-3">4.1 Description</h3>
          <p>
            These are the known use problems with previous models of the device
            and/or similar devices:
          </p>
          <List list={frontmatter.useProblemsList} />
        </div>
        <div className="block">
          <h3 className="title is-3">
            4.2 Design Modification in Response to Use Problems
          </h3>
          <p>
            The modifications that were implemented into the {productName} in
            response to the above use problems are:
          </p>
          <List list={frontmatter.modificiationsList} />
        </div>
      </section>
      <section className="section sectionbody">
        <h1 className="title is-1">5 Hazard Analysis</h1>
        <div className="block">
          <h4 className="title is-3">5.1 Potential Use Errors</h4>
          <Table table={frontmatter.potentialUseErrorsTable} />
        </div>
        <div className="block">
          <h3 className="title is-3">
            5.2 Effectiveness of Risk Control Measure
          </h3>
          <p>
            The risk control measures have been proven to address the potential
            use errors in the following ways:
          </p>
          <List list={frontmatter.measureEffectivenessReasonsList} />
        </div>
      </section>
      <section className="section sectionbody">
        <h1 className="title is-1">6 Preliminary Analyses and Evaluations</h1>
        <div className="block">
          <h3 className="title is-3">6.1 Formative Evaluations</h3>
          <p>
            Formative evaluations were conducted as the primary preliminary
            analysis of the {productName}. In these evaluations, particular
            design elements were examined and any newly discovered use errors
            were documented and addressed accordingly.
          </p>
          <p>
            Link to
            <Link to={`/${productName}/formativeEvaluation`}>
              Formative Evaluation
            </Link>
          </p>
        </div>
        <div className="block">
          <h3 className="title is-3">6.2 Key Findings</h3>
          <p>
            These are the key findings of the formative evaluations and the
            resulting modifications to the device:
          </p>
          <Table table={frontmatter.keyFindingsTable} />
          <p>
            As a result of these key findings, the Human Factors Validation
            Testing will include:
          </p>
          <List list={frontmatter.validationTestsList} />
        </div>
      </section>
      <section className="section sectionbody">
        <h1 className="title is-1">7 Critical Tasks</h1>
        <p>
          The following critical tasks were identified from the key findings of
          the Preliminary Analysis (in Section 6) and review of the Risk
          Management File:
        </p>
        <Table table={frontmatter.criticalTasksTable} />
        <p>
          Based on these tasks, the Human Factors Validation Testing includes
          the following Hazard-Based Use Scenarios:
        </p>
        <Table table={frontmatter.hazardBasedUseScenarioTable} />
      </section>
      <section className="section sectionbody">
        <div className="block">
          <h1 className="title is-1">8 Human Factors Validation Testing</h1>
          <p>
            The Human Factors Validation Test was conducted as a{' '}
            {frontmatter.testType} because {frontmatter.testTypeReason}.
          </p>

          <h3 className="title is-3">8.1 Environment</h3>
          <p>The test was administered in {frontmatter.testEnvironment}.</p>
        </div>
        <div className="block">
          <h3 className="title is-3">8.2 Participants</h3>
          <p>
            Participants in the usability test consisted of{' '}
            {frontmatter.participants} for a total of{' '}
            {frontmatter.numberOfParticipants}
            participants.
          </p>
        </div>
        <div className="block">
          <h3 className="title is-3">8.3 Provided Training</h3>
          <p>{frontmatter.providedTraining}</p>
        </div>
        <div className="block">
          <h3 className="title is-3">8.4 Critical Tasks</h3>
          <p>
            During the test, participants were instructed to complete the
            following primary tasks:
          </p>
          <Table table={frontmatter.primaryTaskTable} />
        </div>
        <div className="block">
          <h3 className="title is-3">8.5 Data Collection</h3>
          <p>
            Data collected from each individual participant was recorded by the
            test moderator on a Participant Evaluation Form (See Appendix).
          </p>
          <p>The form consists of</p>
          <ol>
            <li>Participant information</li>
            <li>Task instructions</li>
            <li>General Observations</li>
            <li>Participant Performance</li>
            <li>Participant Feedback</li>
          </ol>
          <p>Actual session data can be found in KT document</p>
        </div>
        <div className="block">
          <h3 className="title is-3">8.6 Results Summary</h3>
          <p>
            The following use errors/participant difficulties were observed
            during the test:
          </p>
          <Table table={frontmatter.useErrorsTable} />
          <p>Key Participant Feedback:</p>
          <List list={frontmatter.keyParticipantFeedbackList} />
        </div>
        <div className="block">
          <h3 className="title is-3">8.7 Conclusion</h3>
          <p>{frontmatter.conclusion}</p>
          <p>Actions to Take:</p>
          <List list={frontmatter.actionsList} />
        </div>
      </section>
      <section className="section sectionbody">
        <h1 className="title is-2">Appendix A: Training Materials</h1>
        <h1 className="title is-2">
          Appendix B: Labelling Materials (IFU, User Manual, quick-start guides,
          packaging, etc.)
        </h1>
        <h1 className="title is-2">Participant Evaluation Form</h1>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query MarkdownQueryUsabilityEngineering($filePath: String) {
    markdownRemark(fields: { filePath: { eq: $filePath } }) {
      frontmatter {
        templateName
        relevantDesignModificationsList {
          modification
        }
        properUseScenariosList {
          scenario
        }
        residualRisksList {
          risk
        }
        riskJustificationReason
        intendedUseDescription
        intendedUseSummary
        usersTable {
          users
          preTrainingProductKnowledge
          productTrainingNeeded
        }
        impactsofDifferencesList {
          impact
        }
        expectedUseEnvironmentsTable {
          environment
          problematicEnvironmentalCharacteristics
        }
        requirementsList {
          requirement
        }
        materialsList {
          material
        }
        hasUserInterface
        userInterface
        userInterfacesTable {
          userInterfacingComponent
          picture
        }
        orderOfOperationsTable {
          action
          deviceResponse
        }
        accompanyingMaterialsList {
          material
        }
        useProblemsList {
          problem
        }
        modificiationsList {
          modification
        }
        potentialUseErrorsTable {
          useError
          hazardousSituation
          potentialHarm
          harmSeverity
          riskControlMeasure
          riskControlProof
        }
        measureEffectivenessReasonsList {
          reason
        }
        keyFindingsTable {
          issue
          resultingModification
        }
        validationTestsList {
          test
        }
        criticalTasksTable {
          task
          taskDescription
          potentialHarm
          harmSeverity
        }
        hazardBasedUseScenarioTable {
          useScenario
          procedure
        }
        testType
        testTypeReason
        testEnvironment
        participants
        numberOfParticipants
        providedTraining
        primaryTaskTable {
          task
          definitionOfSuccess
        }
        useErrorsTable {
          useError
          numberOfOccurences
          rootCause
          riskImplication
        }
        keyParticipantFeedbackList {
          feedback
        }
        conclusion
        actionsList {
          action
        }
      }
    }
  }
`
